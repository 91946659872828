import type { ThemeConfig } from 'antd/es/config-provider'

export const commonTheme: ThemeConfig = {
  token: {
    fontFamily: 'Poppins, sans-serif',
    colorPrimary: '#F5A32C',
    fontSizeHeading1: 40,
    lineHeightHeading1: 1.5,
    fontSizeHeading2: 32,
    fontSizeHeading3: 24,
    fontSizeHeading5: 16,

    fontWeightStrong: 500,
  },
  components: {
    Layout: {
      colorBgBody: 'transparent',
    },
    Menu: {
      radiusItem: 14,
      fontSize: 15,
      padding: 20,
    },
    Tabs: {
      colorPrimaryActive: '#F5A32C',
      fontSizeLG: 18,
      padding: 12,
    },
    Button: {
      colorPrimary: '#F5A32C',
      colorText: '#F5A32C',
      colorBgTextHover: 'rgba(245, 163, 44, 0.06)',
      colorBgTextActive: 'rgba(245, 163, 44, 0.15)',
      colorLink: '#F5A32C',
      colorLinkHover: '#ffd480',
      colorLinkActive: '#cf811b',
      borderRadius: 14,
      borderRadiusLG: 16.5,
      borderRadiusSM: 11.2,
      colorTextLightSolid: 'rgba(0, 0, 0, 0.88)',
      controlHeightSM: 32,
      fontSizeSM: 12,
      controlHeight: 40,
      fontSize: 15,
      controlHeightLG: 48,
      fontSizeLG: 18,
    },
    Input: {
      colorPrimary: '#F5A32C',
      borderRadius: 14,
      controlHeight: 40,
      controlHeightLG: 48,
      borderRadiusLG: 16.5,
      lineWidth: 1.5,
    },
    Select: {
      colorPrimary: '#F5A32C',
      borderRadius: 14,
      controlHeight: 40,
    },
    Card: {
      borderRadiusLG: 14,
      colorBorderSecondary: 'transparent',
      padding: 16,
      paddingLG: 16,
    },
    Radio: {
      fontSizeSM: 12,
      borderRadiusSM: 7,
      borderRadius: 11.2,
      borderRadiusLG: 14,
      colorTextLightSolid: 'rgba(0, 0, 0, 0.88)',
      colorBorder: 'transparent',
    },
    Drawer: {},
    DatePicker: {
      colorPrimary: '#F5A32C',
      borderRadius: 11.2,
      lineWidth: 1.5,
    },
    Checkbox: {
      controlInteractiveSize: 20,
      borderRadiusSM: 7,
      lineWidth: 1.5,
    },
    Table: {
      borderRadiusLG: 0,
    },
  },
}
