import type { LayerProps } from 'react-map-gl'

export const clusterLayer: LayerProps = {
  id: 'clusters',
  type: 'circle',
  source: 'machines',
  filter: ['has', 'point_count'],
  paint: {
    'circle-color': '#f5a32c',
    'circle-radius': 15,
  },
}

export const clusterCountLayer: LayerProps = {
  id: 'cluster-count',
  type: 'symbol',
  source: 'machines',
  filter: ['has', 'point_count'],
  layout: {
    'text-field': '{point_count_abbreviated}',
    'text-size': 12,
  },
}

export const unclusteredPointLayer: LayerProps = {
  id: 'unclustered-point',
  type: 'circle',
  source: 'machines',
  filter: ['!', ['has', 'point_count']],
  paint: {
    'circle-color': '#f5a32c',
    'circle-radius': 7,
  },
}
